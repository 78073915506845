interface navBarItem {
  title: string;
  link: string;
  icon: string[];
  afterLogin: boolean;
  isRouteLink: boolean;
  isPopUp: boolean;
  validateLogin: boolean;
  validateBank: boolean;
  view: string[];
  ignoreMaintenance: boolean;
}

export const navBar: navBarItem[] = [
  {
    title: "Home",
    link: "/",
    icon: ["fas", "house-chimney"],
    afterLogin: false,
    isRouteLink: true,
    isPopUp: false,
    validateLogin: false,
    validateBank: false,
    view: ["pc", "mobile"],
    ignoreMaintenance: true,
  },
  {
    title: "SLOT",
    link: "slot",
    icon: ["fas", "dice-six"],
    afterLogin: false,
    isRouteLink: false,
    isPopUp: false,
    validateLogin: true,
    validateBank: false,
    view: ["pc", "mobile"],
    ignoreMaintenance: false,
  },
  {
    title: "Promotion",
    link: "/promotion",
    icon: ["fas", "gift"],
    afterLogin: false,
    isRouteLink: true,
    isPopUp: false,
    validateLogin: false,
    validateBank: false,
    view: ["pc", "mobile"],
    ignoreMaintenance: true,
  },
  {
    title: "VIP",
    link: "/vip",
    icon: ["fa", "star"],
    afterLogin: false,
    isRouteLink: true,
    isPopUp: false,
    validateLogin: false,
    validateBank: false,
    view: [""],
    ignoreMaintenance: true,
  },
  {
    title: "Contact Us",
    link: "/contact",
    icon: ["fas", "headset"],
    afterLogin: false,
    isRouteLink: true,
    isPopUp: false,
    validateLogin: false,
    validateBank: false,
    view: ["pc", "mobile"],
    ignoreMaintenance: true,
  },
  {
    title: "Language",
    link: "changeLanguage",
    icon: ["fas", "language"],
    afterLogin: false,
    isRouteLink: false,
    isPopUp: false,
    validateLogin: false,
    validateBank: false,
    view: [],
    ignoreMaintenance: true,
  },
  {
    title: "Interchange",
    link: "interchange-modal",
    icon: ["fas", "shuffle"],
    afterLogin: false,
    isRouteLink: false,
    isPopUp: true,
    validateLogin: false,
    validateBank: false,
    view: ["mobile"],
    ignoreMaintenance: true,
  },
  {
    title: "Profile",
    link: "updateProfile-modal",
    icon: ["fas", "user"],
    afterLogin: true,
    isRouteLink: false,
    isPopUp: true,
    validateLogin: false,
    validateBank: false,
    view: ["mobile"],
    ignoreMaintenance: true,
  },
  {
    title: "GameIDInfo",
    link: "gameIdInfo-modal",
    icon: ["fas", "id-card"],
    afterLogin: true,
    isRouteLink: false,
    validateLogin: false,
    isPopUp: true,
    validateBank: false,
    view: ["mobile"],
    ignoreMaintenance: true,
  },
  {
    title: "Bank Info",
    link: "bankInfo-modal",
    icon: ["fas", "money-bill-trend-up"],
    afterLogin: true,
    isRouteLink: false,
    isPopUp: true,
    validateLogin: false,
    validateBank: false,
    view: ["mobile"],
    ignoreMaintenance: true,
  },
  {
    title: "Statement",
    link: "/statement",
    icon: ["fas", "file-invoice"],
    afterLogin: true,
    isRouteLink: true,
    isPopUp: false,
    validateLogin: false,
    validateBank: false,
    view: ["mobile"],
    ignoreMaintenance: true,
  },
  {
    title: "Referral",
    link: "/referral",
    icon: ["fas", "people-arrows-left-right"],
    afterLogin: true,
    isRouteLink: true,
    isPopUp: false,
    validateLogin: false,
    validateBank: false,
    view: ["mobile"],
    ignoreMaintenance: true,
  },
  {
    title: "Deposit",
    link: "deposit-modal",
    icon: ["fas", "wallet"],
    afterLogin: true,
    isRouteLink: false,
    isPopUp: true,
    validateLogin: false,
    validateBank: true,
    view: ["mobile"],
    ignoreMaintenance: false,
  },
  {
    title: "Withdrawal",
    link: "withdrawal-modal",
    icon: ["fas", "money-bill-transfer"],
    afterLogin: true,
    isRouteLink: false,
    isPopUp: true,
    validateLogin: false,
    validateBank: true,
    view: ["mobile"],
    ignoreMaintenance: false,
  },

  {
    title: "Change Password",
    link: "changePassword-modal",
    icon: ["fas", "key"],
    afterLogin: true,
    isRouteLink: false,
    isPopUp: true,
    validateLogin: false,
    validateBank: false,
    view: ["mobile"],
    ignoreMaintenance: false,
  },
  {
    title: "Logout",
    link: "logout",
    icon: ["fas", "right-from-bracket"],
    afterLogin: true,
    isRouteLink: false,
    isPopUp: false,
    validateLogin: false,
    validateBank: false,
    view: ["mobile"],
    ignoreMaintenance: true,
  },
];
