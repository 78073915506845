export default {
	ABOUTUS: "关于我们",
	Description1:
		"Live22 于2019年10月在马来西亚推出。该游戏受到每个赌场玩家的喜爱，因此赢得了马来西亚第二大赌场游戏。游戏列表：GreatBlue，Safari Heat，DolphinReef，冰岛，泰拳，二十一点，轮盘，百家乐和其他游戏都在里面。",
	Description2:
		"马来西亚的每个人都喜欢这个游戏，因为它可以随时随地在现代手机上播放，适合18岁以上的人群。利用您的业余时间，通过这个赌博游戏赢更多的钱！就像您在云顶高原的SkyCasino中赢钱一样，您还在犹豫吗？现在开始玩！",
	Description3:
		"您可以在计算机上播放，而无需下载Android模拟器。该应用程序具有计算机版本和Android或IOS Iphone，它为您提供最佳的安全性和保护，您无需担心数据泄漏，比任何平台都更安全。请在此处下载游戏客户端apk。",
	Description4:
		"该游戏功能每天都有任务奖励供您完成，并每天获得更多奖励。每日社交宾果游戏是Live22推出的新功能！每天都有一天所有玩家免费玩的宾果游戏！最低奖励为60，最高为2500令吉或更高。",
	Description5:
		"幸运轮也是Live22推出的最新彩票功能！当天的玩家可以获得免费的星星，可以将其交换为幸运轮或神秘盒子！可以收集您的星星并立即使用，必须在当天使用，否则24小时后会刷新，请记住它，否则您将后悔。",
	Description6: "Live22具有现场娱乐场和电子老虎机，可在一个游戏应用程序中下载并立即开始游戏！",
};
