<template>
	<b-container fluid class="pb-5">
		<b-container class="py-1">
			<div class="homeHistoryContainer py-2 mb-5 mx-3">
				<div class="homeHistoryContent m-3">
					<div class="homeHistoryText my-2">{{ $t("HomeHistoryCompany.AboutLive22") }}</div>
					<i18n-t tag="div" keypath="HomeHistoryCompany.DescText" class="textDesc">
						<template #newline>
							<div class="my-2"></div>
						</template>
					</i18n-t>
				</div>
			</div>
		</b-container>
	</b-container>
</template>

<style scoped>
.homeHistoryContainer {
	background: #cccccc;
	border: 3px solid #61d1ff;
	border-radius: 10px;
	padding: 1rem;
}
.homeHistoryContent {
	color: #000000;
	text-align: left;
	font-size: 14px;
	font-family: "oswaldregular", sans-serif;
	text-align: justify;
}
.homeHistoryText {
	font-size: 1.5rem;
	padding-bottom: 1rem;
	text-align: left;
}
</style>
<script>
export default {
	data() {
		return {};
	},
	computed: {
		isLogin() {
			return this.$store.getters["identityServer/getLoginStatus"];
		},
		checkIsMemberBankExist() {
			return this.$store.getters["member/getIsMemberBankExist"];
		},
		checkIsLoadMemberBank() {
			return this.$store.getters["member/getOnLoadMemberBankStatus"];
		},
	},
	components: {},
	methods: {},
};
</script>
