import { MemberWinoverResponse } from "@/types/MemberAccount/Response/MemberWinoverResponse";
import { MemberTurnoverResponse } from "@/types/MemberAccount/Response/MemberTurnoverResponse";
import { MemberAccountResponse } from "@/types/MemberAccount/Response/MemberAccountResponse";
import { memberProductUsernameResponse } from "@/types/MemberAccount/Response/MemberProductUsernameResponse";
import { MemberVendorBalanceByTenantIdResponse } from "@/types/MemberAccount/Response/MemberVendorBalanceByTenantIdResponse";

export interface MemberAccountStateInterface {
	balance: number;
	memberWinover: MemberWinoverResponse;
	memberTurnover: MemberTurnoverResponse;
	memberAccount: MemberAccountResponse;
	memberProductUsername: memberProductUsernameResponse;
	MemberVendorBalanceByTenantId: MemberVendorBalanceByTenantIdResponse[];
}

function state(): MemberAccountStateInterface {
	return {
		balance: 0,
		memberWinover: {} as MemberWinoverResponse,
		memberTurnover: {} as MemberTurnoverResponse,
		memberAccount: {} as MemberAccountResponse,
		memberProductUsername: {} as memberProductUsernameResponse,
		MemberVendorBalanceByTenantId: [] as MemberVendorBalanceByTenantIdResponse[]
	};
}

export default state;
