<template>
	<div v-for="navbar in navbarList" :key="navbar.title">
		<template v-if="isMaintenance && !navbar.ignoreMaintenance">
			<div class="navLink" @click="maintenancepopup" v-if="isLogin">{{ $t("HeaderNavLink." + navbar.title) }}</div>
			<div class="navLink" v-b-modal="'login-modal'" v-else>{{ $t("HeaderNavLink." + navbar.title) }}</div>
		</template>
		<template v-else-if="navbar.isRouteLink === true && navbar.isPopUp === false">
			<router-link :to="navbar.link">
				<div class="navLink">{{ $t("HeaderNavLink." + navbar.title) }}</div>
			</router-link>
		</template>
		<template v-else>
			<div class="navLink" @click="doAction(navbar.link)" v-if="isLogin">{{ $t("HeaderNavLink." + navbar.title) }}</div>
			<div class="navLink" v-b-modal="'login-modal'" v-else>{{ $t("HeaderNavLink." + navbar.title) }}</div>
		</template>
	</div>
</template>

<style scoped>
.navIconContent {
	background: #3e0d0c;
	box-shadow: -2px -5px 10px #801b19;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	margin: 0 auto;
}

.navIconContent:hover,
.router-link-active .navIconContent,
.router-link-exact-active .navIconContent {
	background: #ed6461;
	box-shadow: -2px -5px 10px #f38f8c;
}

.navIcon {
	color: #ed6461;
}

.navIconContent:hover .navIcon,
.router-link-active .navIcon,
.router-link-exact-active .navIcon {
	color: #000;
}

.navLink {
	color: #cccccc;
	padding-top: 10px;
	font-family: SF Pro Display;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	margin: 0px 1rem;
	cursor: pointer;
}

a,
a:hover {
	text-decoration: none;
}

@media (max-width: 991px) {
	.navLink {
		font-size: 11px;
	}
}

@media (max-width: 767px) {
	.navLink {
		padding-top: 2px;
	}

	.navIconContent {
		box-shadow: -2px -2px 10px #801b19;
	}

	.navIconContent:hover,
	.router-link-active .navIconContent,
	.router-link-exact-active .navIconContent {
		box-shadow: -2px -2px 10px #f38f8c;
	}
}
</style>

<script>
import { navBar } from "@/constant";
import { GameType } from "@/common/GameType.ts";

export default {
	computed: {
		isLogin() {
			return this.$store.getters["identityServer/getLoginStatus"];
		},
		navbarList() {
			var navBarFiltered = navBar.filter((i) => i.view.includes("pc"));
			if (!this.isLogin) {
				navBarFiltered = navBarFiltered.filter((i) => i.afterLogin == false);
			}
			return navBarFiltered;
		},
		isMaintenance() {
			return this.$store.getters["vendors/getVendor"].maintenance;
		},
	},
	methods: {
		doAction(action) {
			if (action == "slot") {
				this.playSlot();
			} else if (action == "casino") {
				this.playCasino();
			}
		},
		async playSlot() {
			let loader = this.$loading.show();
			await this.$store.dispatch("downloadLinks/fetchDownloadLinks", { downloadType: "IOS" }).then(() => {
				loader.hide();
				const tempGameUrl = this.$store.getters["downloadLinks/getDownloadLinks"]("IOS").downloadUrl;
				window.open(tempGameUrl, "_blank");
			});
			// await this.$store
			// 	.dispatch("games/fetchLaunchGameUrl", { GameType: GameType.SLOTS, Lang: localStorage.getItem("locale") })
			// 	.then((url) => {
			// 		window.open(url, "_blank");
			// 		loader.hide();
			// 	})
			// 	.catch(() => {
			// 		loader.hide();
			// 		let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
			// 		this.toast.error(this.$t("HomeHotSlot.LaunchGameError"), { position: toastPosition });
			// 	});
		},
		async playCasino() {
			let loader = this.$loading.show();
			await this.$store
				.dispatch("games/fetchLaunchGameUrl", { GameType: GameType.LIVE_CASINO, Lang: localStorage.getItem("locale") })
				.then((url) => {
					window.open(url, "_blank");
					loader.hide();
				})
				.catch(() => {
					loader.hide();
					let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
					this.toast.error(this.$t("HomeHotSlot.LaunchGameError"), { position: toastPosition });
				});
		},
		async maintenancepopup() {
			let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
			this.toast.error(this.$t("HomeHotSlot.GameMaintenance"), {
				position: toastPosition,
				timeout: 0,
				//onClose: () => this.redirectHomePage(),
			});
		},
	},
};
</script>
