<template>
	<b-container>
		<div class="runningTextBg" v-b-modal="'runningtext-modal'">
			<div class="runningTextContent">
				<Vue3Marquee class="marquee text-white" :duration="30" :pause-on-hover="true" :pause-on-click="true">
					<span v-for="(runningText, index) in getRunningText" :key="`runningText-${index}`" class="runningTextItem">{{
						runningText
					}}</span>
				</Vue3Marquee>
			</div>
		</div>
	</b-container>

	<RunningTextModal :runningTextList="runningTextList"></RunningTextModal>
</template>

<script>
import RunningTextModal from "@/components/modal/RunningTextModal.vue";
export default {
	components: {
		RunningTextModal,
	},
	data() {
		return {
			runningTextList: [],
		};
	},
	watch: {
		"$i18n.locale": function (newLanguage) {
			this.runningTextList = this.$store.getters["announcement/getRunningText"](newLanguage);
		},
	},
	computed: {
		getRunningText() {
			return this.runningTextList;
		},
	},
	async created() {
		await this.$store.dispatch("announcement/fetchRunningText").then(() => {
			this.runningTextList = this.$store.getters["announcement/getRunningText"]();
		});
	},
};
</script>

<style scoped>
.runningTextBg {
	height: 30px;
	display: flex;
	cursor: pointer;
	align-items: center;
	background: #1a1a1a;
}
.runningTextContent {
	/*max-width: 85%;*/
	color: #ffffff;
	align-items: center;
	display: block;
	font-size: 0.8rem;
	width: 100%;
}
.runningTextItem {
	margin: 0px 3rem;
	color: #ffffff;
}

@media (max-width: 767px) {
	.runningTextBg {
		height: 100%;
		font-size: 0.8rem;
		padding: 0.275rem 0px;
	}
}
</style>
