<template>
	<b-container fluid class="mt-5">
		<b-container>
			<div class="d-flex justify-content-between align-items-center">
				<div class="d-flex align-items-center">
					<base-image :imgSrc="homeHotSlotIcon" :isFullUrl="false"></base-image>
					<span class="homeSlot-title">{{ $t("HomeHotSlot.SlotsGame") }}</span>
				</div>
				<template v-if="isLogin">
					<div class="moreGames" @click="startPlay">
						{{ $t("HomeHotSlot.moreGames") }}
					</div>
				</template>
				<template v-else>
					<div class="moreGames" v-b-modal="'login-modal'">
						{{ $t("HomeHotSlot.moreGames") }}
					</div>
				</template>
			</div>
			<div class="slotGameContainer">
				<div class="gameItemContainer" v-for="gameItem in gameList" :key="gameItem">
					<div class="gameItem">
						<div class="gameItem-image">
							<base-image :imgSrc="hotGameImagePath + gameItem.GameImage"></base-image>
						</div>
						<div class="gameTitle">{{ gameItem.GameName }}</div>
					</div>
					<div class="gameItem-overlay">
						<template v-if="isLogin">
							<b-button variant="main" @click="startPlay">{{ $t("HomeHotSlot.PlayNow") }}</b-button>
						</template>
						<template v-else>
							<b-button variant="main" v-b-modal="'login-modal'">{{ $t("HomeHotSlot.PlayNow") }}</b-button>
						</template>
					</div>
				</div>
			</div>
		</b-container>
	</b-container>
</template>
<style scoped>
.homeSlot-title {
	margin-left: 1rem;
	color: #61d1ff;
	font-size: 1.5rem;
	white-space: nowrap;
}
.moreGames {
	cursor: pointer;
	font-size: 1.5rem;
}
.slotGameContainer {
	display: flex;
	flex-wrap: wrap;
}
.gameItemContainer {
	padding: 0.2rem;
	width: 14.2857%;
	position: relative;
}

.gameItem {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;
	background: #262626;
	border: 1px solid #2b8fc8;
	padding: 1rem;
	height: 100%;
}
.gameItem-image {
	width: 100%;
}
.gameTitle {
	font-size: 1.2rem;
	color: #ffffff;
	margin-top: 0.5rem;
}

.gameItemContainer .gameItem-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 2px solid #2b8fc8;
	display: none;
}
.gameItemContainer:hover .gameItem-overlay {
	display: flex;
	background: rgba(0, 0, 0, 0.7);
	padding: 1rem;
	justify-content: center;
	align-items: center;
}
@media (max-width: 767px) {
	.moreGames {
		font-size: 0.9rem;
	}
	.gameItemContainer {
		width: 25%;
	}
	.gameItem {
		padding: 0.5rem;
	}
	.gameTitle {
		font-size: 0.85rem;
	}
}
@media (max-width: 1200px) {
}
@media (max-width: 1640px) {
}
</style>
<script>
import { GameType } from "@/common/GameType.ts";
import BaseImage from "@/components/dynamic/BaseImage.vue";

export default {
	data() {
		return {
			homeHotSlotIcon: "live22/assets/images/Home/icon-slot.png",
			hotGameImagePath: "live22/assets/images/Home/HotSlotGames/",
			gameList: [
				{
					GameImage: "blackpink.jpeg",
					GameName: "BlackPink",
				},
				{
					GameImage: "classicDiamondX5.jpeg",
					GameName: "Classic Diamond x5",
				},
				{
					GameImage: "fongshen.jpeg",
					GameName: "Feng Shen",
				},
				{
					GameImage: "illuvium.jpeg",
					GameName: "Illuvium",
				},
				{
					GameImage: "axieUniverse.jpeg",
					GameName: "Axie Universe",
				},
				{
					GameImage: "luckyCoins.jpeg",
					GameName: "Lucky Coins",
				},
				{
					GameImage: "imperialKingdom.jpeg",
					GameName: "Imperial Kingdom",
				},
				{
					GameImage: "princessTheEvilWitch.jpeg",
					GameName: "Princess The Evil Witch",
				},
				{
					GameImage: "happyLantern.jpeg",
					GameName: "Happy Lantern",
				},
				{
					GameImage: "PandasRealm.jpeg",
					GameName: "Panda's Realm",
				},
				{
					GameImage: "highwayKings.jpeg",
					GameName: "Highway Kings",
				},
				{
					GameImage: "dragonsWish.jpeg",
					GameName: "Dragon's Wish",
				},
				{
					GameImage: "menInBlack.jpeg",
					GameName: "Men In Black",
				},
				{
					GameImage: "dazzlingStar.jpeg",
					GameName: "Dazzling Star",
				},
			],
		};
	},
	components: { BaseImage },
	methods: {
		async startPlay() {
			let loader = this.$loading.show();
			await this.$store.dispatch("downloadLinks/fetchDownloadLinks", { downloadType: "IOS" }).then(() => {
				loader.hide();
				const tempGameUrl = this.$store.getters["downloadLinks/getDownloadLinks"]("IOS").downloadUrl;
				window.open(tempGameUrl, "_blank");
			});
		},
		// async startPlay() {
		// 	let loader = this.$loading.show();
		// 	await this.$store
		// 		.dispatch("games/fetchLaunchGameUrl", { GameType: GameType.SLOTS, Lang: localStorage.getItem("locale") })
		// 		.then((url) => {
		// 			loader.hide();
		// 			window.open(url, "_blank");
		// 		})
		// 		.catch(() => {
		// 			loader.hide();
		// 			let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
		// 			this.toast.error(this.$t("HomeHotSlot.LaunchGameError"), { position: toastPosition });
		// 		});
		// },
		async maintenancepopup() {
			let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
			this.toast.error(this.$t("HomeHotSlot.GameMaintenance"), {
				position: toastPosition,
				timeout: 0,
				//onClose: () => this.redirectHomePage(),
			});
		},
	},
	computed: {
		isLogin() {
			return this.$store.getters["identityServer/getLoginStatus"];
		},
		checkIsMemberBankExist() {
			return this.$store.getters["member/getIsMemberBankExist"];
		},
		isMaintenance() {
			return this.$store.getters["vendors/getVendor"].maintenance;
		},
	},
};
</script>
