<template>
	<b-container fluid class="my-5 px-col-2">
		<b-container class="pt-3 pb-2 my-1">
			<div class="homeTitle text-start ps-3">{{ $t("HomePromotion.PROMOTIONS") }}</div>
			<Carousel
				v-if="promoList.length !== 0"
				id="promoCarousel"
				class="bannerMax"
				:autoplay="4000"
				:wrap-around="true"
				pause-autoplay-on-hover
				:settings="settings"
				:breakpoints="breakpoints"
			>
				<slide v-for="promoItem in promoList" :key="promoItem">
					<div class="promoItem mx-3">
						<BaseImage
							:imgSrc="imgUrl(promoItem)"
							mode="promoImg"
							:isFullUrl="false"
							fluid-grow
							@click="openPromoPage"
						></BaseImage>
					</div>
				</slide>
			</Carousel>
		</b-container>
	</b-container>
</template>

<script>
import BaseImage from "@/components/dynamic/BaseImage.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";
import { getDefaultCultureCode, getCultureID } from "@/currentCulture";
export default {
	components: {
		Carousel,
		Slide,
		BaseImage,
	},
	data() {
		return {
			imagePath: "Bonus/BannerImage/",
			promoList: [],
			settings: {
				itemsToShow: 1,
				snapAlign: "center",
			},
			breakpoints: {
				767: {
					itemsToShow: 3,
					snapAlign: "center",
				},
				1024: {
					itemsToShow: 3,
					snapAlign: "start",
				},
			},
		};
	},
	methods: {
		imgUrl(promoItem) {
			const cultureCode = getDefaultCultureCode();
			const currentLocaleID = getCultureID(cultureCode);
			const thisDescriptionItem = promoItem.description.filter((x) => x.languageId == currentLocaleID);
			return this.imagePath + thisDescriptionItem[0].image;
		},
		openPromoPage() {
			this.$router.push("/promotion");
		},
	},
	computed: {},
	watch: {},
	async mounted() {
		await this.$store.dispatch("bonus/fetchBonusList").then(() => {
			let promoList = this.$store.getters["bonus/getBonusList"].filter((bonus) => bonus.type == "Pre Bonus");
			this.promoList = promoList.slice(0, 4);
			// this.promoList = promoList.map((promoItem) => {
			//   promoItem.imgUrl = this.imagePath + promoItem.imgUrl;
			//   return promoItem;
			// });
		});
	},
};
</script>
<style scoped>
.promoImg {
	width: 100%;
	height: auto;
	max-width: 100%;
	max-height: 221px;
	border-radius: 0px;
}
.promoItem {
	margin: 1rem 0rem;
	background: #5099cc;
	padding: 0.5rem;
	cursor: pointer;
	border-radius: 5px;
}
.promoDesc {
	border-right: 1px solid #002e8c;
	background-color: #001942;
	padding: 10px;
}
</style>
<style>
.carousel__viewport {
	background-color: transparent !important;
}
#promoCarousel .carousel__next:hover,
#promoCarousel .carousel__prev:hover {
	opacity: 100%;
}
</style>
