<template>
	<b-container>
		<Carousel
			v-if="bannerListByLang.length !== 0"
			id="homeCarousel"
			class="bannerMax"
			:autoplay="4000"
			:wrap-around="true"
			pause-autoplay-on-hover
		>
			<slide v-for="(bImg, bIndex) in bannerList" :key="bImg.id">
				<BaseImage
					:imgSrc="bannerListByLang[bIndex].imgURL"
					mode="mainBanner"
					:isFullUrl="true"
					fluid-grow
					@click="bannerLink(bImg.url)"
				></BaseImage>
			</slide>

			<template #addons>
				<Navigation />
				<Pagination />
			</template>
		</Carousel>
	</b-container>
</template>

<script>
import BaseImage from "@/components/dynamic/BaseImage.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Pagination, Slide, Navigation } from "vue3-carousel";
import { getCultureID } from "@/currentCulture";
export default {
	components: {
		Carousel,
		Slide,
		Pagination,
		Navigation,
		BaseImage,
	},
	data() {
		return {
			bannerList: [],
			bannerListByLang: [],
			showBanner: false,
		};
	},
	computed: {
		getBannerList() {
			return this.$store.getters["banner/getBanner"](this.$isMobile());
		},
	},
	methods: {
		bannerLink(url) {
			if (url.includes(".")) {
				if (!/^https?:\/\//i.test(url)) {
					url = "http://" + url;
				}
				window.open(url);
			} else {
				this.$router.push(url.toLowerCase());
			}
		},
	},
	watch: {
		"$i18n.locale": function (newLanguage) {
			const currentLocaleID = getCultureID(newLanguage);
			this.bannerList = this.$store.getters["banner/getBanner"](this.$isMobile());
			this.bannerListByLang = this.bannerList.map((bannerItem) => {
				return bannerItem.banner
					.filter((x) => x.languageId == currentLocaleID)
					.map((banner) => {
						return banner;
					})[0];
			});
		},
	},
	async mounted() {
		let languageCode = "";
		const currentLocaleID = getCultureID(languageCode);
		await this.$store.dispatch("banner/fetchBanner").then(() => {
			this.bannerList = this.$store.getters["banner/getBanner"](this.$isMobile());
			this.bannerListByLang = this.bannerList.map((bannerItem) => {
				return bannerItem.banner
					.filter((x) => x.languageId == currentLocaleID)
					.map((banner) => {
						return banner;
					})[0];
			});
		});
	},
};
</script>

<style>
.carousel__viewport {
	background-color: #000000;
}

.carousel__prev,
.carousel__next {
	box-sizing: content-box;
	transform: none;
	background-color: transparent;
	height: 100%;
	top: 0;
	padding: 0 5vw;
}

.carousel__pagination {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 15;
	display: -ms-flexbox;
	-ms-flex-pack: center;
	padding-left: 0;
	margin: 0 auto 2vw auto;
}

.carousel__pagination-button {
	width: 2vw;
	background-color: #3c86b7;
}

.carousel__pagination-button.carousel__pagination-button--active {
	background-color: #61d1ff;
}
</style>
