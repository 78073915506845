<template>
	<b-container fluid>
		<b-container class="pt-4 pb-2">
			<div class="d-flex">
				<div class="downloadItem-container">
					<div class="downloadItem">
						<b-row align-v="center" align-h="center">
							<b-col cols="12" md="8">
								<div class="d-flex justify-content-center align-items-center flex-column">
									<div class="installation-title">
										<font-awesome-icon :icon="['fas', 'download']" />
										<span class="ms-3">{{ $t("HomeDownload.Installation") }}</span>
									</div>
									<b-button variant="main" class="btn-download" @click="downloadGame(androidDownloadUrl)">
										<font-awesome-icon :icon="['fab', 'android']" />
										<span class="ms-2">{{ $t("HomeDownload.AndroidDownload") }}</span>
									</b-button>
								</div>
							</b-col>
							<b-col md="4" class="d-none d-md-block">
								<qrcode-vue :value="androidDownloadUrl" level="H" class="my-2 w-75 h-auto mx-auto" />
							</b-col>
						</b-row>
					</div>
				</div>
				<div class="downloadItem-container">
					<div class="downloadItem">
						<b-row align-v="center" align-h="center">
							<b-col cols="12" md="8">
								<div class="d-flex justify-content-center align-items-center flex-column">
									<div class="installation-title">
										<font-awesome-icon :icon="['fas', 'download']" />
										<span class="ms-3">{{ $t("HomeDownload.Installation") }}</span>
									</div>
									<b-button variant="main" class="btn-download" @click="downloadGame(iosDownloadUrl)">
										<font-awesome-icon :icon="['fab', 'apple']" />
										<span class="ms-2">{{ $t("HomeDownload.IosDownload") }}</span>
									</b-button>
								</div>
							</b-col>
							<b-col md="4" class="d-none d-md-block">
								<qrcode-vue :value="iosDownloadUrl" level="H" class="my-2 w-75 h-auto mx-auto" />
							</b-col>
						</b-row>
					</div>
				</div>
			</div>
		</b-container>
	</b-container>
</template>
<script>
import QrcodeVue from "qrcode.vue";
export default {
	data() {
		return {
			androidQrUrl: require("@/assets/android.png"),
			iosQrUrl: require("@/assets/ios.png"),
			iosDownloadUrl: "",
			androidDownloadUrl: "",
			html5Url: "https://001.new9k.com/h5",
		};
	},
	methods: {
		downloadGame(downloadUrl) {
			window.open(downloadUrl, "_blank");
		},
	},
	components: {
		QrcodeVue,
	},
	async created() {
		await this.$store.dispatch("downloadLinks/fetchDownloadLinks", { downloadType: "ANDROID" });
		await this.$store.dispatch("downloadLinks/fetchDownloadLinks", { downloadType: "IOS" });
		this.androidDownloadUrl = this.$store.getters["downloadLinks/getDownloadLinks"]("ANDROID").downloadUrl;
		this.iosDownloadUrl = this.$store.getters["downloadLinks/getDownloadLinks"]("IOS").downloadUrl;
	},
};
</script>
<style scoped>
.homeDownloadTitle {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: calc(10px + 1.16vw);
	line-height: 56%;
	text-align: center;
	color: #f03f5a;
}
.homeDownloadDesc {
	margin-top: 1.6rem;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	font-size: calc(10px + 0.42vw);
	line-height: 118%;
	text-align: center;
	color: #ffffff;
}

.installation-title {
	text-transform: uppercase;
	margin-bottom: 1rem;
	letter-spacing: 3px;
	font-size: 1.5rem;
	text-shadow: 0 1px 7px rgb(0 45 102 / 75%);
}
.downloadItem-container {
	width: 50%;
}
.downloadItem-container:nth-child(odd) {
	margin-right: 0.5rem;
}
.downloadItem-container:nth-child(even) {
	margin-left: 0.5rem;
}
.downloadItem {
	background: linear-gradient(45deg, #3c86b7 50%, #498cb7 50%);
	padding: 1rem;
}

.btn-download {
	width: 90%;
	font-size: 1.3rem;
	padding: 1rem 0px !important;
}

@media (max-width: 767px) {
	.downloadBg {
		background-image: none !important;
	}
	.installation-title {
		font-size: 0.9rem;
	}
}
</style>
