import { ActionTree } from "vuex";
import { getLaunchGameUrl, getGameList, getGameCategories } from "@/network/GamesService/Games";
import { GamesStateInterface } from "./state";
import { RootState } from "@/store/state";
import { GamesMutations } from "./mutations";

export const actions: ActionTree<GamesStateInterface, RootState> = {
	async fetchLaunchGameUrl(context, payload) {
		const vendorId = context.rootGetters["vendors/getVendor"].id;
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const res = await getLaunchGameUrl(vendorId, memberId, payload.GameType, payload.IsMobile, payload.Lang);
		return res;
	},
	async fetchGameList(context, payload) {
		const vendorId = context.rootGetters["vendors/getVendor"].id;
		const res = await getGameList(20, payload.GameCategory);
		context.commit(GamesMutations.SET_GAMES_LIST, res);
	},
	async fetchGameCategory(context, payload) {
		const vendorId = context.rootGetters["vendors/getVendor"].id;
		const res = await getGameCategories(20);
		return res;
	},
};
